.alice-carousel {
  background-size: cover;
  background-image: linear-gradient(
      rgb(255 255 255 / 80%),
      rgb(255 255 255 / 80%)
    ),
    url(../../images/business-men.jpg);
  min-height: calc(100vh - 65px);
  position: absolute;
  z-index: -1;
}
